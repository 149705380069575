const appRoutes = {
  homePageRoutes: {
    homePageRoutes: "/",
  },
  about: {
    about: "/about",
  },
  services: {
    services: "/services",
  },
  work: {
    work: "/work"
  },
  contact: {
    contact: "/contact"
  },
  workshop: {
    workshop: "/workshop"
  },

  hdfc: {
    hdfc: "/hdfc"
  },
  polycab: {
    polycab: "/polycab"
  },
  wipro: {
    wipro: "/Vivo"
  },
  kei: {
    kei: "/kei"
  },
  wavin: {
    wavin: "/wavin"
  }
  
};

export default appRoutes;

//Registration URL
