import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0095DA",
    },
  },
  typography: {
    h2: {
      fontSize: "30px",
      fontWeight: 700,
      color: "#222222",
    },
  },
});
export default theme;
