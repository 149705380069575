import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Routes from "./Routes";
import store from "./Redux/Store/index";
import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/scss/main.scss";

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Provider store={store()}>
    <Routes />
  </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
