import { lazy } from "react";

import appRoutes from "../../appRoutes";

const HomePage = lazy(() => import("./Pages/HomePage"));
const About = lazy(() => import("./Pages/About.jsx") );
const Services = lazy(() => import("./Pages/Services.jsx") );
const Work = lazy(() => import("./Pages/Work.jsx") );
const Contact = lazy(() => import("./Pages/Contact.jsx"));
const Workshop = lazy(() => import("./Pages/Workshop.jsx"));

const HDFC = lazy(() => import("./Pages/HDFC.jsx"));
const Polycab = lazy(() => import("./Pages/Polycab.jsx"));
const Vivo = lazy(() => import("./Pages/Vivo.jsx"));
const Kei = lazy(() => import("./Pages/KEI.jsx"));
const Wavin = lazy(() => import("./Pages/Wavin.jsx"));




const homePageRoutes = [
  {
    path: appRoutes.homePageRoutes.homePageRoutes,
    exact: true,
    name: "HomePage",
    component: HomePage,
    private: false,
  },
  {
    path: appRoutes.about.about,
    exact: true,
    name: "about",
    component: About,
    private: false,
  },
  {
    path: appRoutes.services.services,
    exact: true,
    name: "Services",
    component: Services,
    private: false,
  },
  {
    path: appRoutes.work.work,
    exact: true,
    name: "Work",
    component: Work,
    private: false,
  },
  {
    path: appRoutes.contact.contact,
    exact: true,
    name: "Contact",
    component: Contact,
    private: false,
  },
  {
    path: appRoutes.workshop.workshop,
    exact: true,
    name: "workshop",
    component: Workshop,
    private: false,
  },
  {
    path: appRoutes.hdfc.hdfc,
    exact: true,
    name: "hdfc",
    component: HDFC,
    private: false,
  },
  {
    path: appRoutes.polycab.polycab,
    exact: true,
    name: "polycab",
    component: Polycab,
    private: false,
  },
  {
    path: appRoutes.wipro.wipro,
    exact: true,
    name: "wipro",
    component: Vivo,
    private: false,
  },
  {
    path: appRoutes.kei.kei,
    exact: true,
    name: "kei",
    component: Kei,
    private: false,
  },
  {
    path: appRoutes.wavin.wavin,
    exact: true,
    name: "wavin",
    component: Wavin,
    private: false,
  },
];

export default homePageRoutes;
